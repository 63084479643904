<template>
  <v-card
    :loading="loading"
    class="mx-auto mb-5"
    width="374"
    elevation="0"
    style="border-radius: 0"
  >
    <v-card-text class="teal--text text--lighten-1 text-center"><h3>События</h3></v-card-text>

    <v-list dense>
      <v-list-group
        color="teal"
        v-model="listActive"
        prepend-icon="mdi-calendar-expand-horizontal"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Задать диапазон дат</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item class="pl-4">

          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDateFiltered"
                label="От"
                color="teal lighten-1"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="teal lighten-1"
              locale="RU-ru"
              v-model="fromDate"
              @input="menu = false"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-menu>
        </v-list-item>

        <v-list-item class="pl-4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDateFiltered"
                color="teal lighten-1"
                label="До"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              @input="menu2 = false"
              color="teal lighten-1"
              locale="RU-ru"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-menu>

        </v-list-item>

        <v-list-item class="pl-4">
          <v-row justify="space-around">
            <v-btn @click="showEventsForDates()" dark color="teal lighten-1">Показать</v-btn>
            <v-btn @click="clearDatesRange()">Очистить</v-btn>
          </v-row>
        </v-list-item>

      </v-list-group>
    </v-list>

    <v-text-field
      v-model="filter"
      prepend-icon="mdi-account-search"
      label="Поиск"
      color="teal lighten-1"
    ></v-text-field>

    <v-card
      v-for="event in filteredEvents(filter)"
      :key="event._id"
      class="pl10 mt30 mx-1 elevation-5 rounded"
      :lodaing="eventsLoading"
    >
      <v-card-text style="margin-top: -20px">
        <div class="text-right" style="font-size: 12px">
          <small class="grey--text lighten-4">{{ event.eventDay | date }} {{ event.eventTime }}</small>
        </div>

        <div style="margin-top: -24px">
          <div>
            <b v-if="event.event"
               class="mt-4"
               :class="`${event.new ? 'indigo--text text--accent-4' : 'grey--text lighten-4'}`"
            >
              Новая запись
            </b>
            <b v-else
               class="mt-4"
               :class="`${event.new ? 'red--text text--darken-3' : 'grey--text lighten-4'}`"
            >
              Отмена
            </b>
          </div>

          <div>
            <b>Дата: </b>{{ event.date | date }}
          </div>

          <div>
            <b>Время: </b>{{ event.time }}
          </div>

          <div><b>Телефон: </b>{{ event.phone }} {{ event.phoneOwner }}</div>

          <div>
            <b>Клиент: </b>
            {{ event.firstName }}
            {{ event.lastName }}
          </div>

          <div>
            <b>Услуга: </b>
            {{ event.title }}:
            {{ event.services }}
          </div>
        </div>

      </v-card-text>

    </v-card>

  </v-card>
</template>

<script>

import dateFilter from "@/filters/dateFilter";

export default {
  name: "Events",
  data: () => ({
    loading: false,
    eventsLoading: false,
    listActive: false,
    menu: false,
    menu2: false,
    fromDate: "",
    toDate: "",
    filter: "",
    events: []
  }),

  computed: {
    fromDateFiltered: {
      get: function() {
        return dateFilter(this.fromDate)
      },
      set: function(date) {
        this.fromDate = date
      }
    },

    toDateFiltered: {
      get: function() {
        return dateFilter(this.toDate)
      },
      set: function(date) {
        this.toDate = date
      }
    },
  },

  methods: {
    async loadEventsList() {
      this.eventsLoading = true
      const json = await this.authFetch('/event/load-events-for-last-three-days', {})
      this.events = json.events
      this.$store.commit('setQty', this.events.filter(e => e.new).length)
      this.events.sort(this.sortEventsFunc)
      this.eventsLoading = false
    },

    filteredEvents(str) {
      if (!this.events.length) return []
      if (!this.filter) return this.events

      const filtered = this.events
        .map(e => JSON.stringify({
          eventDay: e.eventDay,
          eventTime: e.eventTime,
          date: e.date,
          time: e.time,
          phone: e.phone,
          phoneOwner: e.phoneOwner,
          firstName: e.firstName,
          lastName: e.lastName,
          title: e.title,
          services: e.services,
          event: e.event,
          owner: e.owner,
          id: e._id
        }))
        .filter(j => j.toLowerCase().includes(str.toLowerCase()))
        .map(j => JSON.parse(j))

      return this.events.filter(e => filtered.map(f => f.id).includes(e._id))
    },

    clearDatesRange() {
      this.fromDate = ""
      this.toDate = ""
      this.loadEventsList()
    },

    async showEventsForDates() {
      if (!this.fromDate && !this.toDate) return

      this.eventsLoading = true
      let toDate = this.toDate || dateFilter(Date.now(), 'ISO')
      this.toDate = toDate
      let fromDate = this.fromDate || toDate
      this.fromDate = fromDate
      if (toDate < fromDate) {
        [toDate, fromDate] = [fromDate, toDate]
        this.toDate = toDate
        this.fromDate = fromDate
      }
      const json = await this.authFetch('/event/load-events-for-dates', {fromDate, toDate})
      this.events = json.events
      this.$store.commit('setQty', this.events.filter(e => e.new).length)
      this.events.sort(this.sortEventsFunc)
      this.eventsLoading = false
    },

    allowedDates(val) {
      return (new Date(Date.now()) > new Date(val))
    }
  },

  async mounted() {
    await this.loadEventsList()
    this.$store.commit('setQty', 0)
  },

  beforeDestroy() {
    const eventsIds = this.events.filter(e => e.new).map(e => e._id)
    if (!eventsIds.length) return
    this.$store.commit('setQty', 0)
    this.authFetch('/event/read-events', {eventsIds})
  }

}
</script>

<style scoped>

</style>

